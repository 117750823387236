<template>
  <div class="dashboard">
    <Filters
      :load-on-init="!isAdmin"
      :loading="loading"
      :on-change="onChangeFilters"
      :use-categories="false"
      :useContentType="false"
      @filterError="onErrorFilters"
    />

    <div class="row row-equal" v-if="!noData">
      <InsightPerTime :data="timeStats"/>
      <InsightsStatuses :data="statusesStat"/>
    </div>

    <InsightsTable :data="dataStats" v-if="!noData"></InsightsTable>
    <NoDataFound v-if="noData"/>
  </div>
</template>

<script>
import ElasticAPI from '../../services/API/ElasticAPI';
import {getPeriodFromDates, refactoringByTimeZone} from "../../services/Helpers/HelperTime";
import fetchFilters from "../../mixins/fetchFilters";
import {showToastError} from "@/services/Helpers/HelperToast";
import NoDataFound from "@/components/stats/NoDataFound";

export default {
  name: 'dashboard',
  mixins: [fetchFilters],

  components: {
    NoDataFound,
    InsightPerTime: () => import('../stats/InsightPerTime'),
    InsightsStatuses: () => import('../stats/InsightsStatuses'),
    InsightsTable: () => import('../tables/InsightsTable'),
    Filters: () => import('../filters/Filters'),
  },

  data() {
    return {
      timeStats: {
        labels: [],
        datasets: [],
      },
      statusesStat: {
        labels: [],
        datasets: [],
      },
      dataStats: [],
      noData: false,
      isAdmin: false
    };
  },

  created() {
    this.isAdmin = JSON.parse(localStorage.getItem('ttlUserInfo'))?.roles.includes('ROLE_ADMIN')
    this.loading = !this.isAdmin
  },

  methods: {

    refresh(data) {
      this.statusesStat = this.getInsightsStatuses(data);
      this.timeStats = this.getInsightsTimeStat(data, this.filters.range.time_local.gte, this.filters.range.time_local.lte);
      this.dataStats = this.getInsightsTableStat(data);
      this.loading = false;
    },

    getInsightsTimeStat(data, dateFrom, dateTo) {
      let datasets = [];
      let labels = [];
      const period = getPeriodFromDates(dateFrom, dateTo);
      const statusesData = {};
      const colors = {
        'Unknown Product': "rgba(69, 219, 84, 0.5)",
        /*Broken: "rgba(243, 151, 43, 0.5)",
        Empty: "rgba(214, 222, 226, 0.5)",*/
      };

      data.forEach(info => {
        let {time_local, status} = info;
        time_local = refactoringByTimeZone(time_local, null);

        if (!statusesData?.[status]) {
          statusesData[status] = {};
        }

        let formats = {
          "1d": "DD-MM-YYYY",
          "1Y": "YYYY",
          "1M": "MM-YYYY",
        };

        time_local = period === "1h" ? time_local.hours() + ":00" : time_local.format(formats?.[period]);

        if (!labels.includes(time_local)) {
          labels.push(time_local);
        }

        if (!statusesData[status]?.[time_local]) {
          statusesData[status][time_local] = 1;
        } else {
          statusesData[status][time_local]++;
        }

      });

      labels = labels.reverse();

      Object.keys(statusesData).forEach(status => {

        const obj = {
          label: status,
          data: [],
          fill: true,
          lineTension: 0.57,
          backgroundColor: colors[status],
          borderColor: "transparent",
        };

        labels.forEach(() => {
          obj.data.push(0);
        });

        Object.keys(statusesData[status]).forEach((count, index) => {
          const labelIndex = labels.findIndex(label => label === count);
          if (labelIndex !== -1) {
            obj.data[labelIndex] = statusesData[status][count];
          }
        });

        datasets.push(obj);

      });

      return {labels, datasets};
    },

    getInsightsStatuses(data) {
      let datasets = [];
      const retailerData = {};
      let statuses = [];
      let labels = [];

      const colors = {
        'Unknown Product': "rgba(69, 219, 84, 1)",
        /*Broken: "rgba(243, 151, 43, 1)",
        Empty: "rgba(214, 222, 226, 1)",*/
      };

      data.forEach(info => {
        const {status, retailer_name} = info;

        if (!labels.includes(retailer_name)) {
          labels.push(retailer_name);
        }

        if (!statuses.includes(status)) {
          statuses.push(status);
          const obj = {
            label: status,
            data: [],
            backgroundColor: colors[status],
            borderColor: "transparent",
          };
          datasets.push(obj);
        }

        if (!retailerData?.[retailer_name]) {
          retailerData[retailer_name] = {};
        }

        if (!retailerData[retailer_name]?.[status]) {
          retailerData[retailer_name][status] = 0;
        }

        retailerData[retailer_name][status]++;
      });

      const retailerCount = labels.length;

      Object.keys(retailerData).forEach(retailer => {
        const retailerStatuses = retailerData[retailer];

        datasets = datasets.map(obj => {

          if (obj.length < 1) {
            for (let i = 0; i < retailerCount; i++) {
              obj.data.push(0);
            }
          }

          if (Object.keys(retailerStatuses).includes(obj.label)) {
            const findIndex = labels.indexOf(retailer);
            obj.data[findIndex] = retailerStatuses[obj.label];
          }

          return obj;
        });

      });

      return {datasets, labels};
    },

    getInsightsTableStat(data) {
      const datasets = [];

      let epackages = {};

      data.forEach(info => {
        const {product, status, license_name, retailer_name} = info;

        if (status !== "Unknown Product") {
          return false;
        }

        if (epackages?.[product]) {
          epackages[product].count++;
        } else {
          epackages[product] = {}
          epackages[product]['count'] = 1;
          epackages[product]['license_name'] = license_name;
          epackages[product]['retailer_name'] = retailer_name;
        }

      });

      const sortable = [];

      for (let product in epackages) {
        sortable.push([
          product,
          epackages[product].count,
          epackages[product].license_name,
          epackages[product].retailer_name,
        ]);
      }

      sortable.sort((a, b) => a[1] > b[1] ? -1 : a[1] < b[1] ? 1 : 0)

      sortable.forEach(product => {
        const obj = {
          key: `https://www.google.com/search?q=${product[0]}+${product[3]}`,
          product: product[0],
          doc_count: product[1],
          license_name: product[2],
          retailer_name: product[3],
        };
        datasets.push(obj);
      });

      return datasets
    },

    async refreshData() {
      this.defaultFilters["range"] = this.filters.range;
      this.defaultFilters["contentType"] = this.filters.contentType;

      try {
        const data = await ElasticAPI.getInsights(this.filters);
        if (!data.length) {
          this.noData = true
        } else {
          this.noData = false
        }
        await this.refresh(data);
      } catch (error) {
        this.noData = true
        console.error(error);
      }
    },
  },

};
</script>

<style lang="scss">
.row-equal .flex {
  .vac-card {
    height: 100%;
  }
}

.dashboard {
  .vac-card {
    margin-bottom: 0 !important;
  }
}

.link-icon {
  color: #000000;
  transition: 0.3s;
  cursor: pointer;
}

.link-icon:hover {
  opacity: 0.7;
}

.td--small {
  width: 20%;
}

.dashboard-contributors-list {
  flex-direction: column;
  height: 100%;
}

.inner-loading {
  table {
    width: 100%;
  }

  .va-table td {
    word-break: break-all;
  }

  tbody,
  thead {
    width: 100%;
  }
}

</style>
